import { settingDefaultValue } from '../../views/containers/DataboxForm/config'
export const defaultSettings = {
  ...settingDefaultValue,
  retrievalMode: 'SemanticHybrid',
  maxSource: 12
}
// ** Returns Initial Menu Collapsed State
const getInitialState = () => {
  const item = window.localStorage.getItem('chatStore')
  //** Parse stored json or if none return initialValue
  return item
    ? JSON.parse(item)
    : {
        tagFilters: [],
        selectedTeam: [],
        selectedDataBox: [],
        chatSettings: defaultSettings,
        selectedFiles: [],
        isDataboxChat: false,
        databoxSettings: undefined,
        databoxFilters: [],
        dataBoxDefaultSetting: undefined
      }
}

// ** Initial State
const initialState = getInitialState()

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'HANDLE_TAG_FILTERS':
      return { ...state, tagFilters: action.value }

    case 'SET_TEAM':
      return { ...state, selectedTeam: action.value }

    case 'SET_DATA_BOX':
      return { ...state, selectedDataBox: action.value }

    case 'SET_CHAT_SETTING':
      return { ...state, chatSettings: action.value }

    case 'SET_MULTI_DOC_FILES':
      return { ...state, selectedFiles: action.value }

    case 'SET_IS_DATA_BOX_CHAT':
      return { ...state, isDataboxChat: action.value.value, dataBoxId: action.value.id }

    case 'SET_DATABOX_DEFAULT_SETTINGS':
      return { ...state, dataBoxDefaultSetting: action.value }

    case 'SET_DATABOX_SETTINGS':
      return { ...state, databoxSettings: action.value }

    case 'SET_DATABOX_FILTERS':
      return { ...state, databoxFilters: action.value }

    default:
      return state
  }
}

export default chatReducer
